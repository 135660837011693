<template>
  <div class="container">
    <!-- <div class="container-login" v-if="!isShowForm">
      <div class="login">
        <h1>Đăng nhập</h1>
        <form class="form-group">
          <el-input
            v-model="emailLogin"
            type="email"
            class="form-control"
            placeholder="Email"
            required
          />
          <el-input
            v-model="passwordLogin"
            type="password"
            class="form-control"
            placeholder="Password"
            required
          />
          <el-button type="danger" round @click="doLogin">Primary</el-button>
        </form>
      </div>
    </div> -->

    <div class="container-home">
      <div class="container-req" v-if="reqParentInfo"> 
        <p
          class="des-p"
          style="
            color: #006161;
            margin-top: 0px;
            font-weight: bold;
            font-size: 15px;
          "
        >
          Chi tiết công việc gia sư
        </p>

        <p style="font-size: 14px">
          Phụ huynh: {{ reqParentInfo.NameParent }} -
          {{ reqParentInfo.PhoneEmail }}
        </p>
        <p style="font-size: 14px">
          Địa chỉ: {{ reqParentInfo.AddressParent }}
        </p>
        <p style="font-size: 14px">
          Trình độ: {{ reqParentInfo.ValueClass }} -
          {{ reqParentInfo.Subjects }}
          <span v-if="reqParentInfo.SkillSupport">
            - {{ reqParentInfo.SkillSupport }}</span
          >
        </p>
        <p style="font-size: 14px">
          Thời gian: {{ reqParentInfo.TimeSupport }}
        </p>
        <p style="font-size: 14px">
          Hình thức: {{ reqParentInfo.FormTeach }} -
          {{ reqParentInfo.QuantityStudent }}
        </p>
      </div>
      <div class="container-bill">
        <h3 class="title">Phiếu ứng tuyển gia sư</h3>
        <p
          class="des-p"
          style="
            color: #006161;
            margin-top: 20px;
            font-weight: bold;
            font-size: 15px;
          "
        >
          Thông tin của bạn
        </p>
        <p class="des-p">Họ và tên</p>
        <el-input
          size="small"
          placeholder="Nhập tên của bạn"
          v-model="nameStudent"
        >
        </el-input>
        <p class="des-p">Số điện thoại</p>
        <el-input
          size="small"
          placeholder="Nhập tên của bạn"
          v-model="phoneNumber"
        >
        </el-input>
        <p class="des-p">Địa chỉ</p>
        <el-input
          size="small"
          placeholder="Nhập địa chỉ của bạn"
          v-model="address"
        >
        </el-input>
        <p class="des-p">Mục tiêu dạy kèm của bạn</p>
        <el-checkbox-group v-model="perposeTeach">
          <div v-for="item in listPerposeTeach" :key="item">
            <el-checkbox
              :label="item"
              border
              size="small"
              style="margin-bottom: 4px; max-width: 100%"
            ></el-checkbox>
          </div>
        </el-checkbox-group>
        <p class="des-p">Bạn có thể giúp người học</p>
        <el-checkbox-group v-model="supports">
          <div v-for="item in listSupport" :key="item">
            <el-checkbox
              :label="item"
              border
              size="small"
              style="margin-bottom: 4px; max-width: 100%"
            ></el-checkbox>
          </div>
        </el-checkbox-group>
        <p class="des-p">Kỹ năng</p>

        <div>
          <div style="display: flex; justify-content: space-between">
            <span style="font-size: 14px">Toán</span>
            <span style="color: #009394">{{ valueSubjectMath }}/10</span>
          </div>

          <el-slider
            v-model="valueSubjectMath"
            :max="maxLine"
            :show-tooltip="false"
            style="margin-top:-8px"
          ></el-slider>
        </div>
        <div>
          <div style="display: flex; justify-content: space-between">
            <span style="font-size: 14px">Tiếng anh</span>
            <span style="color: #009394">{{ valueSubjectEng }}/10</span>
          </div>

          <el-slider
            v-model="valueSubjectEng"
            :max="maxLine"
            :show-tooltip="false"
            style="margin-top:-8px"
          ></el-slider>
        </div>
        <div>
          <div style="display: flex; justify-content: space-between">
            <span style="font-size: 14px">Vật lý</span>
            <span style="color: #009394">{{ valueSubjectPhy }}/10</span>
          </div>

          <el-slider
            v-model="valueSubjectPhy"
            :max="maxLine"
            :show-tooltip="false"
            style="margin-top:-8px"
          ></el-slider>
        </div>
        <div>
          <div style="display: flex; justify-content: space-between">
            <span style="font-size: 14px">Hóa học</span>
            <span style="color: #009394">{{ valueSubjectChem }}/10</span>
          </div>

          <el-slider
            v-model="valueSubjectChem"
            :max="maxLine"
            :show-tooltip="false"
            style="margin-top:-8px"
          ></el-slider>
        </div>
        <div>
          <div style="display: flex; justify-content: space-between">
            <span style="font-size: 14px">Ngữ văn</span>
            <span style="color: #009394">{{ valueSubjectLit }}/10</span>
          </div>

          <el-slider
            v-model="valueSubjectLit"
            :max="maxLine"
            :show-tooltip="false"
            style="margin-top:-8px"
          ></el-slider>
        </div>
        <p class="des-p">Học phí mong muốn</p>
        <div>
          <div style="display: flex; justify-content: space-between">
            <span style="font-size: 14px">Khoảng</span>
            <span style="color: #009394; font-weight:500"
              >{{ new Intl.NumberFormat().format(valuePrice) }} VNĐ</span
            >
          </div>

          <el-slider
            v-model="valuePrice"
            :max="maxMoney"
            :show-tooltip="false"
            style="margin-top:-8px"
          ></el-slider>
        </div>
        <p class="des-p">Tóm tắt bản thân</p>
        <p
          style="font-size: 12px; color: #e9446a"
          v-if="summaryLst.length == 0"
        >
          Thêm mô tả về bản thân mà bạn muốn
        </p>
        <div
          v-for="(sum, index) in summaryLst"
          :key="index"
          style="display: flex"
        >
          <p
            style="
              font-size: 12px;          
              margin: 0;
              margin-bottom: 8px;
              margin-left: 8px;
            "
          >
            <span>{{ sum }}</span>
          </p>
          <button
            style="background: none; border: none; color: #454545; height: 16px"
            @click="btDeleteSum(sum)"
          >
            <i class="el-icon-close"></i>
          </button>
        </div>
        <div style="display: flex">
          <el-input
            placeholder="Nhập mô tả"
            v-model="inputSummary"
            size="small"
          ></el-input>
          <el-button type="primary" plain size="small" @click="addSummary"
            >Thêm</el-button
          >
        </div>
        <p class="des-p">Thông tin thêm</p>
        <el-input
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 6 }"
          placeholder="Nhập thông tin thêm"
          v-model="infoMore"
        >
        </el-input>
        <div style="margin-top: 16px; text-align: center">
          <el-button
            type="primary"
            size="small"
            :loading="isClickBtSend"
            @click="btConfirm"
            >Gửi thông tin</el-button
          >
        </div>
      </div>
    </div>

    <div class="container-notify" v-if="isShowNotifySuccess">
      <div class="notify">
        <el-result
          icon="success"
          title="Đăng ký gia sư thành công"
          subTitle="Chúng tôi sẽ phản hồi vào email hoặc số điện thoại mà bạn đã cung cấp"
        >
          <template slot="extra">
            <el-button type="primary" size="small">Xác nhận</el-button>
          </template>
        </el-result>
      </div>
    </div>
  </div>
</template>

<script>
// import { infoTeacher } from "../../firebase-config";
import { CheckStudentApply, StudentApply } from "../../api/studentApi";
export default {
  data() {
    return {
      listSupport: [
        "Khích lệ, giải tỏa áp lực học tập",
        "Hướng dẫn phương pháp học tập",
        "Xây dựng động lực học tập",
        "Cải thiện kết quả",
      ],
      listPerposeTeach: [
        "Luyện tính kiên nhẫn, kỹ năng giao tiếp",
        "Cùng cố kiến thức kỹ năng gia sư",
        "Thêm kinh phí hỗ trợ học tập",
      ],
      supports: [],
      perposeTeach: [],
      summaryLst: [],
      inputSummary: "",
      infoMore: "",
      nameStudent: "",
      phoneNumber: "",
      address: "",
      valueSubjectMath: 5,
      valueSubjectEng: 5,
      valueSubjectPhy: 5,
      valueSubjectChem: 5,
      valueSubjectLit: 5,
      subjects: [],
      isShowNotifySuccess: false,
      isClickBtSend: false,
      maxLine: 10,
      reqParentInfo: {},
      maxMoney: 500000,
      valuePrice: 120000,
      // login
      // isShowForm: false,
      // emailLogin: "@sis.hust.edu.vn",
      // passwordLogin: "",
      // emptyFields: false,
    };
  },

  methods: {
    addSubjects() {
      var math = { sub: "Toán", value: this.valueSubjectMath };
      var eng = { sub: "Tiếng Anh", value: this.valueSubjectEng };
      var phy = { sub: "Vật lý", value: this.valueSubjectPhy };
      var chem = { sub: "Hóa học", value: this.valueSubjectChem };
      var lit = { sub: "Ngữ văn", value: this.valueSubjectLit };
      this.subjects = [math, eng, phy, chem, lit];
      console.log(JSON.stringify(this.subjects));
      return JSON.stringify(this.subjects);
    },
    addSummary() {
      if (!this.inputSummary) {
        this.$message({
          message: "Vui lòng nhập mô tả",
          type: "warning",
        });
        return;
      }
      let sum = " - " + this.inputSummary;
      this.inputSummary = "";
      this.summaryLst.push(sum);
    },
    btDeleteSum(sum) {
      this.summaryLst = this.summaryLst.filter((p) => p != sum);
    },
    btCheckFormApply() {
      CheckStudentApply({
        ReqParentID: this.$route.params.req,
        StudentID: this.$route.params.id,
      }).then((res) => {
        if (res.RespCode == 0) {
          var st = res.StudentInfo;
          this.reqParentInfo = res.ReqParentInfo;
          this.nameStudent = st.StudentName;
          this.phoneNumber = st.Phone;
          this.address = st.Address;
        }
      });
    },
    btConfirm() {
      if (this.nameStudent == '') {
        this.$message({
          message: "Vui lòng điền họ và tên",
          type: "warning",
        });
        return;
      }
      if (this.phoneNumber == '') {
        this.$message({
          message: "Vui lòng điền số điện thoại",
          type: "warning",
        });
        return;
      }
      if (this.address == '') {
        this.$message({
          message: "Vui lòng điền địa chỉ",
          type: "warning",
        });
        return;
      }
      if (this.perposeTeach.length == 0) {
        this.$message({
          message: "Vui lòng chọn mục tiêu gia sư",
          type: "warning",
        });
        return;
      }
      if (this.supports.length == 0) {
        this.$message({
          message: "Vui lòng chọn mục tiêu hỗ trợ",
          type: "warning",
        });
        return;
      }
      if (this.summaryLst.length < 2) {
        this.$message({
          message: "Vui lòng thêm tóm tắt bản thân (Ít nhất 2)",
          type: "warning",
        });
        return;
      }
      if (this.infoMore == "") {
        this.$message({
          message: "Vui lòng viết thông tin thêm về mình",
          type: "warning",
        });
        return;
      }
      this.isClickBtSend = true;
      StudentApply({
        ReqParentID: this.$route.params.req,
        StudentID: this.$route.params.id,
        StudentApply: {
          StudentAddress: this.address,
          StudentName: this.nameStudent,
          Email: "",
          StudentPhone: this.phoneNumber,
          Subjects: this.addSubjects(),
          Perposes: this.perposeTeach.join("; "),
          Helps: this.supports.join("; "),
          Summary: this.summaryLst.join("; "),
          InfoMore: this.infoMore,
          ValuePrice: this.valuePrice
        },
      }).then((res) => {
        this.isClickBtSend = false;
        if (res.RespCode == 0) {
          this.$notify({
            title: "Thành công",
            message: "Ứng tuyển thành công. Chúng tôi sẽ sớm liên hệ cho bạn",
            type: "success",
          });
        } else {
          this.$notify.error({
            title: "Thất bại",
            message: "Ứng tuyển thất bạn. Vui lòng thử lại sau",
          });
        }
      });
    },
  },
  created() {
    this.btCheckFormApply();
    // this.addSubjects();
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: #0094947a;
  position: relative;
  // .container-login {
  //   position: fixed;
  //   top: 0;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;
  //   background: #fff;
  //   z-index: 1;

  //   .login {
  //     padding: 32px;
  //     max-width: 600px;
  //     text-align: center;
  //     margin: auto;
  //     background: #009293;
  //     h1 {
  //       color: #fff;
  //       margin-top: 0;
  //     }

  //     .form-control {
  //       padding-bottom: 12px;
  //     }
  //   }
  // }

  .container-home {
    padding-top: 1px;
    padding-bottom: 1px;
    .container-req {
      margin: 10px;
      padding: 16px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.25);
    }
    .container-post {
      margin: 10px;
      padding: 16px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      .title {
        margin: 0;
        margin-bottom: 16px;
        text-align: center;
        color: #006161;
      }
      .image-post {
        width: 100%;
        border-radius: 8px;
      }
      .content {
        margin: 0;
        margin-top: 8px;
      }
    }
    .container-bill {
      margin: 10px;
      padding: 16px;
      background: #fff;
      border-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      .input-level {
        margin-right: 4px;
        width: 100%;
      }
      .input-class {
        margin-left: 4px;
      }
      .title {
        margin: 0 8px 0 0;
        text-align: center;
        color: #006161;
      }
      .des-p {
        margin: 8px 0px;
        font-size: 14px;
        font-weight: 500;
      }
      .bt-subject {
        border: none;
        background: none;
        color: #009394;
      }
      .el-checkbox {
        margin-right: 0px;
      }
    }
    .container-footer {
      background: #009394;
      color: #fff;
      box-shadow: 1px 0 5px rgba(0, 0, 0, 0.15);
      padding: 8px 20px;
      p {
        font-size: 14px;
      }
    }
  }
  .container-notify {
    position: fixed;
    background: rgba(0, 0, 0, 0.54);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    text-align: center;
    transition: all 0.5s ease-in;
    .notify {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: rgb(245, 255, 223);
      width: 360px;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    }
  }
}
</style>
<style>
.container-bill .el-input__inner {
  font-size: 14px !important;
}
.container-bill .el-textarea__inner {
  font-size: 14px !important;
  resize: none;
}
</style>
